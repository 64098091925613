.name-size {
  font-size: 72px;
}

@media only screen and (max-width: 1366px) {
  .name-size {
    font-size: 55px;
  }
}
.description {
  margin: 0;
}
.programmer-svg {
  width: 500px;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
}
.mobile-svg {
  display: none;
}
.about-width {
  width: 75%;
}
/* SVG WIDTH FIX */
@media only screen and (max-width: 1143px) {
  .programmer-svg {
    display: none;
  }
  .mobile-svg {
    display: block;
    position: relative;
    display: flex;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 1040px) {
  .programmer-svg {
    position: relative;
    display: none;
    width: 475px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 991px) {
  .programmer-svg {
    position: relative;
    display: flex;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .mobile-svg  {
    display: none;
  }
  .description {
    margin: 0 3rem;
  }
}
@media only screen and (max-width: 630px) {
  .programmer-svg {
    position: relative;
    display: flex;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .description {
    margin: 0 0;
  }
    .about-width {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .programmer-svg {
    display: none;
  }

}