body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0E0E0F;
  color: white;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
  /* SCROLL BAR */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #0E0E0F;
}
::-webkit-scrollbar-thumb {
  background: #AE45FF;
}
::-webkit-scrollbar-thumb:hover {
  background: #761bbb;
}


.title-text {
    background: -webkit-linear-gradient(#ffffff 60%, #0E0E0F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title-text1 {
    background: -webkit-linear-gradient(#AE45FF 80%, #0E0E0F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.div-divider {
  border-bottom: 1px grey solid;
}
.rotate {
  -webkit-animation: spin 5s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  color:white;
}
